import React, { Component } from "react";
import "../assets/scss/3-elements/_charts.scss";
import TopLeadersList from "../components/TopLeadersList.js";

class Leaders extends Component {
  render() {
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>Top Leaders</h2>
            <TopLeadersList />
          </div>
        </div>
      </div>
    );
  }
}
export default Leaders;
