import React, { Component } from "react";
import TaskIcon from "@mui/icons-material/Task";
import ReadMoreTest from "../components/ReadMoreTes";
import "../assets/scss/3-elements/_history.scss";

const history = `The FTI (formerly known as the African Forest Rangers School, Olmotonyi was established in 1937. The institute is managed by the Ministry of Natural Resources and Tourism. The earlier operations of the Institute (1937) were focused on offering certificate courses to forest guards to equip them with skills necessary for forest protection and management.

In 1968, the ordinary diploma in forestry and later (1969 and 1970) certificates and the ordinary diploma in beekeeping were introduced with the intention of producing experts with skills in their respective fields. The FTI has also offered tailor-made courses since its establishment.


In 1981, certificates and diplomas in beekeeping were relocated to the Beekeeping Training Institute, Tabora (BTI). In 1995, these trainings were moved again to FTI. In 2002, forestry and beekeeping trainings were amalgamated and started to offer certificates in forestry beekeeping and an ordinary diploma in forestry beekeeping. In 2009, the beekeeping training was yet again moved to BTI, and FTI recommenced to offer certificates and ordinary diplomas in forestry. In 2005, the FTI commenced to offer the basic certificate technician, technician certificate, and ordinary diploma in forestry following NACTVET directives. In 2019, the FTI introduced an ordinary diploma in urban and landscaping and an ordinary diploma in geoinformatics for natural resource management. In the upcoming years, FTI plans to present new programs in forestry enterprises.`;
const majorRoles = [
  {
    title: "Professionalism",
    description: "Conduct training in forest and environment.",
  },
  {
    title: "Accountability",
    description:
      "Conduct short courses, research, and offer extension and consultancy services in forest and environment.",
  },
];

class Background extends Component {
  render() {
    const paragraphs = history
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph.trim()}</p>);
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>Our History</h2>
            {paragraphs}
          </div>

          <div className="section">
            <h2>Major Roles</h2>
            <ul>
              {majorRoles.map((value, index) => (
                <li key={index} className="core-value-item">
                  <TaskIcon
                    style={{
                      marginRight: "8px",
                      verticalAlign: "middle",
                      color: "#0d6526",
                      fontSize: "50px",
                    }}
                  />
                  {value.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default Background;
