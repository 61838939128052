import React, { Component } from "react";
import "../assets/scss/3-elements/_charts.scss";
import TeamMemberGrid from "../components/TeamMemberGrid.js";

class OurTeam extends Component {
  render() {
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>The Management Staff</h2>
            <TeamMemberGrid />
          </div>
        </div>
      </div>
    );
  }
}
export default OurTeam;
