import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import axios from "axios";

const $android = "#1DA462";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [newRecord, setNewRecord] = useState({
    news_title: "",
    news_des: "",
    pub_date: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://localhost:8000/api/news`, {
          params: {
            page: page + 1, // API page is usually 1-based, while DataGrid is 0-based
            pageSize,
          },
        });
        setNews(response.data.data.data);
        setRowCount(response.data.data.total); // Assuming API response contains total records
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [page, pageSize]);

  const handleEdit = (id) => {
    // Add your edit logic here
  };

  const handleDelete = (id) => {
    // Add your delete logic here
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setNewRecord({
      ...newRecord,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddRecord = async () => {
    try {
      const response = await axios.post(
        `http://localhost:8000/api/news`,
        newRecord,
      );
      setNews([...news, response.data.data]);
      handleClose();
    } catch (error) {
      console.error("Error adding record:", error);
    }
  };

  const columns = [
    { field: "news_title", headerName: "Title", flex: 1 },
    { field: "news_des", headerName: "Description", flex: 1 },
    { field: "pub_date", headerName: "Published Date", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (news) => (
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => handleEdit(news.id)}
            style={{
              backgroundColor: "#1DA462",
              color: "white",
              marginRight: "8px",
            }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => handleDelete(news.id)}
            style={{ backgroundColor: "#1DA462", color: "red" }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="News" subtitle="News and Updates" />
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        style={{ marginBottom: "20px" }}
      >
        Add News
      </Button>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: $android,
            fontWeight: "bold",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: $android,
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={news}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create News</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details to create news.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="news_title"
            label="Title"
            type="text"
            fullWidth
            value={newRecord.news_title}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="news_des"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={5}
            value={newRecord.news_des}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="pub_date"
            label="Published Date"
            type="date"
            fullWidth
            value={newRecord.pub_date}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddRecord} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Contacts;
