import React, { Component } from 'react';

class BlogPostContent extends Component{
    render(){
            
        /* post tag */
        
        // let tagData = [
        //     {tagLink: "news-left-sidebar", tagName: "Renovation"},
        //     {tagLink: "news-left-sidebar", tagName: "Architecture"},
        //     {tagLink: "news-left-sidebar", tagName: "Concrete"}
        // ];

        // let tagDataList = tagData.map((val, i)=>{
        //     return(
        //         <li key={i}><a href={`${process.env.PUBLIC_URL}/${val.tagLink}`}>{val.tagName}</a></li>
        //     )
        // });

        return(
            <div>
                <div className="row">
                    <div className="blog-details col-12">
                    <div className="blog-inner">
                        <div className="media"><div className="image"><img src="assets/img/blog/blog-details-1.jpg" alt="" /></div></div>
                        <div className="content">
                        <ul className="meta">
                            <li>By <a href="news-left-sidebar">Happiness Shayo </a></li>
                            <li>5 February 2024</li>
                            {/* <li><a href="/">3 Comment</a></li> */}
                        </ul>
                        <h2 className="title">FTI NI CHUO BORA DUNIANI</h2>
                        <div className="desc section-space--bottom--30">
                            <p>Chuo cha Misitu Olmotonyi (FTI) ni chuo pekee kikubwa tangu kuanzishwa kwake mwaka 1937 na ni moja ya chuo bora kabisa kuwepo Afrika Mashariki, Afrika na Duniani, katika utoaji wa mafunzo  ya misitu kwa nadharia na vitendo.</p>
                            <p>Hayo yamesemwa Februari 5,2024 na Waziri wa Maliasili na Utalii, Mhe. Angellah Kairuki (Mb) alipofanya ziara ya kikazi katika chuo hicho kilichopo jijini Arusha.</p>
                            <p>Chuo  hicho kimekuwa kikitoa mafunzo ya misitu katika kozi za Astashahada ya awali ya misitu, Astashahada ya Misitu, Stashahada ya Misitu, Stashahada ya Misitu ya mijini na usanifu wa mandhari, stashahada ya jioinfomantiki katika usimamizi wa maliasili (Geoinformatics for Natural Resources and Management) pamoja na mafunzo ya muda mfupi ya misitu na uhifadhi wa mazingira.</p>
                            <p>Waziri Kairuki amesema ili kukikuza zaidi chuo hicho, Serikali kupitia Wizara ya Maliasili na Utalii inaendelea kufanya mapitio ya mitaala yake  kuhakikisha kwamba inaendana na mahitaji ya sasa ya kidunia katika taaluma ya ulinzi, usimamizi na uendelezaji wa misitu.

Aidha, amesema Wizara itaendelea kuboresha mazingira  ya chuo hicho ikiwa ni pamoja na kuangalia namna ya kuongeza karakana, mashine mpya, majengo mapya na maboresho mengine.</p>
                            <blockquote className="blockquote section-space--bottom--30 section-space--top--30">
                            <p>“Tunaweka mkazo kuhakikisha kwamba tunaboresha miundombinu ili tuweze kuwa na wanafunzi wengi zaidi, wataalamu wa ndani watakaoenda kufanya kazi katika kulinda na kuendeleza misitu yetu ikizingatiwa kwamba Tanzania ina eneo kubwa sana la misitu inayosimamiwa na Serikali Kuu,  Serikali za mitaa kupitia vijiji na watu binafsi hasa tukitambua umuhimu wa misitu yetu katika utunzaji wa mazingira, uwepo wa vyanzo vya maji na masuala mengine kwa ujumla wake” </p>
                            <span className="author">__Mhe. Angellah Kairuki</span>
                            </blockquote>
                            <p>Awali, akizungumza na menejimenti ya chuo hicho , Waziri Kairuki ameitaka kuendelea kukitangaza zaidi chuo hicho.


“Tuendelee kukitangaza chuo kwa kadri inavyowezekana na tujiimarishe zaidi katika utoaji wa mafunzo haya ili tuvutie wataalamu kutoka ndani na  nje ya nchi” amesema Mhe. Kairuki.


 Kwa upande wake Mkuu wa Chuo cha Misitu Olmotonyi,Dkt. Joseph Makero ameahidi  kuwa watumishi wa chuo hicho wanajitahidi  kufanya majukumu yao ingawa chuo kinakabiliwa na upungufu wa watumishi na ufinyu wa bajeti na kwamba wataendele kufanya kazi kwa ufanisi ili kufikia malengo yaliyowekwa.


“Tunaamini kwa kushirikiana na Wizara ya Maliasili na Utalii tutatoa mafunzo mbalimbali ndani na  nje ya nchi” amesema Dkt. Makero.


Ziara hiyo ni mwendelezo wa ziara za kikazi za Waziri Kairuki zenye lengo la kusikiliza changamoto za watumishi na kuzipatia ufumbuzi.</p>
                        </div>
                        {/* <ul className="tags">
                            <li><i className="fa fa-tags" /></li>
                            {tagDataList}
                        </ul> */}
                        </div>
                    </div>
                    </div>
                    <div className="col-12 section-space--top--60">
                    <div className="comment-wrapper">
                        <h3>Leave Your Comment</h3>
                        <div className="comment-form">
                        <form action="#">
                            <div className="row row-10">
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="text" placeholder="Your Name" /></div>
                            <div className="col-md-6 col-12 section-space--bottom--20"><input type="email" placeholder="Your Email" /></div>
                            <div className="col-12"><textarea placeholder="Your Message" defaultValue={""} /></div>
                            <div className="col-12"><input type="submit" defaultValue="Send Comment" /></div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogPostContent;