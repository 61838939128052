import React, { Component } from 'react';

class BlogPostGrid extends Component{
    render(){

        let data = [
            {postLink: "news-details-left-sidebar", postImg: '1.jpg', postDate: 'FEBRUARY 5, 2024', postTitle: 'FTI NI CHUO BORA DUNIANI', postExcerpt:'Hayo yamesemwa na Waziri wa Maliasili na Utalii, Mhe. Angellah Kairuki (Mb) alipofanya ziara ya kikazi katika chuo hicho kilichopo jijini Arusha...'},
            {postLink: "", postImg: '2.jpg', postDate: 'JANUARY 17, 2024', postTitle: 'A goup photo on 57th graduation at FTI led by Director of Forest and Beekeeping Dkt. Deusdedit Bwoyo', postExcerpt:'A goup photo on 57th graduation at FTI led by Director of Forest and Beekeeping Dkt. Deusdedit Bwoyo…'},
            {postLink: "", postImg: '3.jpg', postDate: 'JANUARY 6, 2024', postTitle: 'Olmotonyi College of Forestry Principal Dr. Joseph Makero speaking to journalists at the college.', postExcerpt:'Olmotonyi College of Forestry Principal Dr. Joseph Makero speaking to journalists at the colleg...'},
        ];

        let Datalist = data.map((val, i)=>{
            return(
                <div className="col-sm-6 col-12" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                        <a href={`${process.env.PUBLIC_URL}/${val.postLink}`}><img src={`assets/img/blog/${val.postImg}`} className="img-fluid" alt="" /></a>
                        </div>
                        <div className="blog-post-slider__content">
                        <p className="post-date">{val.postDate}</p>
                        <h3 className="post-title">
                            <a href={`${process.env.PUBLIC_URL}/${val.postLink}`}>{val.postTitle}</a>
                        </h3>
                        <p className="post-excerpt">{val.postExcerpt}</p>
                        <a href={`${process.env.PUBLIC_URL}/${val.postLink}`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                <div className="row">
                    {Datalist}
                </div>
            </div>
        )
    }
}


export default BlogPostGrid;