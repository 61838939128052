import React, { Component} from 'react';
import axios from 'axios';
class BlogGrid extends Component{
 constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    axios.get('http://localhost:8000/api/news')
      .then(response => {
        this.setState({ data: response.data.data.data, loading: false });
        console.log('this is data:', response.data)
      })
      .catch(error => {
        this.setState({ error: error.message, loading: false });
      });
      }

    render(){
        return(
            <div>
                {/*====================  blog grid area ====================*/}
                <div className={`blog-grid-area section-space--inner--120 ${this.props.background}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center section-space--bottom--50">
                                    <h2 className="section-title">News and Publications</h2>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="blog-grid-wrapper">
                                    <div className="row">
        {this.state.data.slice(0,3).map((val, i) => {
            return(
                <div className="col-lg-4" key={i}>
                    <div className="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
                        <div className="blog-post-slider__image section-space--bottom--30">
                            <a href={`${process.env.PUBLIC_URL}/${val.link}`}><img src={`assets/img/blog/${val.image_link}`} className="img-fluid" alt="" /></a>
                            </div>
                            <div className="blog-post-slider__content">
                            <p className="post-date"> {val.pub_date}</p>
                            <h3 className="post-title">
                                <a href={`${process.env.PUBLIC_URL}/${val.link}`}>{val.news_title}</a>
                            </h3>
                            <p className="post-excerpt">{val.postExcerpt}</p>
                            <a href={`${process.env.PUBLIC_URL}/news-details-left-sidebar`} className="see-more-link">SEE MORE</a>
                        </div>
                    </div>
                </div>
            )
        })
        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
  }
}
export default BlogGrid;

