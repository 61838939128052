import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SchoolIcon from "@mui/icons-material/School";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    width: "818px",
    marginBottom: "20px",
  },
  imageCard: {
    flex: "1 1 36.67%",
    width: "300px",
  },
  image: {
    height: "auto",
    borderRadius: "8px",
  },
  description: {
    flex: "1 1 63.33%",
    width: "600px",
    padding: "10px",
  },
  listSection: {
    width: "100%",
  },
  list: {
    padding: "0",
    listStyleType: "none",
  },
};

const departments = [
  {
    name: "Department of Forestry Science",
    head: {
      name: "Mr. Sitta B. Buluma",
      title: "Head of Department",
      description:
        "Department of The FTI formerly known as the African Forest Rangers School Olmotonyi was established in 1937 The institute is managed by the Ministry of Natural Resources and Tourism The earlier operations of the Institute  were focused on offering certificate courses to forest guards to equip them with skills necessary for forest protection and management. In 1968, the ordinary diploma in forestry and later (1969 and 1970) certificates and the ordinary diploma in beekeeping were introduced with the intention of producing experts with skills in their respective fields. The FTI has also offered tailor-made courses since its establishment",
      image: "sitta_m_buluma.jpg",
    },
    courses: [
      "Basic Technician Certificate in Forestry",
      "Technician Certificate in Forestry",
      "Ordinary Diploma in Forestry",
    ],
  },
  {
    name: "Department of Social Forestry and Environment",
    head: {
      name: "Mr. Muzamiru A. Kyaruzi",
      title: "Head of Department",
      description:
        "Department of The FTI formerly known as the African Forest Rangers School Olmotonyi was established in 1937 The institute is managed by the Ministry of Natural Resources and Tourism The earlier operations of the Institute  were focused on offering certificate courses to forest guards to equip them with skills necessary for forest protection and management. In 1968, the ordinary diploma in forestry and later (1969 and 1970) certificates and the ordinary diploma in beekeeping were introduced with the intention of producing experts with skills in their respective fields. The FTI has also offered tailor-made courses since its establishment",
      image: "mzamiru_kyaruzi.jpg",
    },
    courses: ["Ordinary Diploma in Urban Forestry and Landscaping"],
  },
  {
    name: "Department of Geoinformatics for Natural Resources Management",
    head: {
      name: "Mr. Simon K. Nsinda",
      title: "Head of Department",
      description:
        "Department of The FTI formerly known as the African Forest Rangers School Olmotonyi was established in 1937 The institute is managed by the Ministry of Natural Resources and Tourism The earlier operations of the Institute  were focused on offering certificate courses to forest guards to equip them with skills necessary for forest protection and management. In 1968, the ordinary diploma in forestry and later (1969 and 1970) certificates and the ordinary diploma in beekeeping were introduced with the intention of producing experts with skills in their respective fields. The FTI has also offered tailor-made courses since its establishment",
      image: "simon_kitereja_nsinda.jpg",
    },
    courses: [
      "Ordinary Diploma in Geoinformatics For Natural Resources Management",
    ],
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            p: 2,
            alignItems: "flex-start",
            background: "",
            width: "950px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "80vh",
        width: "1200px",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider", minWidth: 200 }}
      >
        {departments.map((department, index) => (
          <Tab
            label={department.name}
            {...a11yProps(index)}
            key={department.name}
          />
        ))}
      </Tabs>
      {departments.map((department, index) => (
        <TabPanel value={value} index={index} key={department.name}>
          <div style={styles.container}>
            <div style={styles.topSection}>
              <div style={styles.imageCard}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "380px",
                  }}
                >
                  <CardMedia
                    sx={{
                      height: 300,
                      width: 300,
                      textAlign: "center",
                      objectFit: "contain",
                    }}
                    image={`${process.env.PUBLIC_URL}/assets/img/team/${department.head.image}`}
                    alt={department.head.name}
                  />
                  <CardContent
                    sx={{
                      height: 80,
                      width: 300,
                      textAlign: "center",
                      lineHeight: 0.7,
                      background: "#0d6526",
                      color: "#fff",
                    }}
                  >
                    <Typography gutterBottom variant="h6" component="div">
                      {department.head.name}
                    </Typography>
                    <Typography
                      sx={{ color: "gold", fontWeight: "bold" }}
                      variant="subtitle1"
                      color="text.secondary"
                    >
                      {department.head.title}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <div style={styles.description}>
                <Box
                  sx={{
                    "& p:first-letter": {
                      fontSize: "xx-large",
                      fontWeight: "bold",
                    },
                    "& p": {
                      textIndent: "2em", // Add this line to indent the first line
                    },
                    display: "flex",
                  }}
                >
                  <Typography>{department.head.description}</Typography>
                </Box>
              </div>
            </div>
            <div style={styles.listSection}>
              <List
                component="ol"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: 2,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#000000",
                    fontWeight: "bold",
                    fontSize: "18px",
                    textAlign: "center",
                    color: "#FFFFFF",
                  }}
                >
                  Programmes Offered
                </Box>
                {department.courses.map((course, idx) => (
                  <ListItem key={idx} sx={{ fontSize: "33x" }}>
                    <ListItemIcon>
                      <SchoolIcon />
                    </ListItemIcon>
                    <ListItemText primary={course} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </TabPanel>
      ))}
    </Box>
  );
}
