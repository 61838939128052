import React from "react";
import chartHtml from "./chart.html";

const encodedHtml = encodeURIComponent(chartHtml);

const MxGraphComponent = () => (
  <div>
    <iframe
      src={`data:text/html;charset=utf-8,${encodedHtml}`}
      width="1100"
      height="900"
      loading="lazy"
      title="Chart"
      style={{ border: "1" }}
    ></iframe>
  </div>
);

export default MxGraphComponent;
