import React from "react";
import { useParams } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFViewer = () => {
  const { url } = useParams();

  return (
    <div style={{ height: "100vh" }}>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.min.js`}
      >
        <Viewer fileUrl={decodeURIComponent(url)} />
      </Worker>
    </div>
  );
};

export default PDFViewer;
