import React from "react";
import "../assets/scss/3-elements/_boardtree.scss";

const members = [
  {
    id: 1,
    name: "Dr. Joseph Makero",
    title: "The College Principal",
    imageUrl: "dr_joseph_makero.jpg",
  },
  {
    id: 2,
    name: "Mr. Stephen Raphael Juma",
    title: "Deputy Principal Academic, Research and Consultancy",
    imageUrl: "stephen_raphael_juma.jpg",
  },
  {
    id: 3,
    name: "Dr. Angelingis Makatta",
    title: "Acting Deputy Principa-Planning Finance and Administration",
    imageUrl: "dr_angelingis_makatta.jpg",
  },
];

const TopLeadersList = () => {
  const renderRows = () => {
    let rows = [];
    let row = [];
    let membersPerRow = [1, 2];
    let memberIndex = 0;

    while (memberIndex < members.length) {
      let membersCount = membersPerRow[rows.length % membersPerRow.length];
      row = members.slice(memberIndex, memberIndex + membersCount);
      rows.push(row);
      memberIndex += membersCount;
    }

    return rows.map((row, index) => (
      <div className="board-row" key={index}>
        {row.map((member) => (
          <div className="board-member" key={member.id}>
            <img
              src={`assets/img/team/${member.imageUrl}`}
              alt={member.name}
              className="member-image"
            />
            <div className="member-info">
              <h3>{member.name}</h3>
              <p>{member.title}</p>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  return <div className="board-members">{renderRows()}</div>;
};

export default TopLeadersList;
