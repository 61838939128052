import React, { Component } from "react";
import ReadMoreTest from "../components/ReadMoreTes";
import "../assets/scss/3-elements/_programs.scss";
import ProgramsOffered from "../components/ProgramsOffered.js";
class Programs extends Component {
  render() {
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>Programmes Offered</h2>
            <ProgramsOffered />
          </div>
        </div>
      </div>
    );
  }
}
export default Programs;
