import React, { Component } from "react";
import Sidebar from "./components/Sidebar";
import BlogPostContent from "./components/BlogPostContent";
class BlogDetailsLeftSidebar extends Component {
  render() {
    return (
      <div>
        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/funfact-bg.jpg)`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>News and Events</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">-FTI-</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  blog details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Blog section start*/}
          <div className="blog-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  {/* blog post content */}
                  <BlogPostContent />
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  {/* blog sidebar */}
                  <Sidebar />
                </div>
              </div>
            </div>
          </div>
          {/*Blog section end*/}
        </div>

        {/*====================  End of blog details page content  ====================*/}
      </div>
    );
  }
}

export default BlogDetailsLeftSidebar;
