import React, { Component } from "react";
import DepartmentTabs from "../components/DepartmentTabs.js";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import InputIcon from "@mui/icons-material/Input";
import { Link, Outlet } from "react-router-dom";
import ReadMoreTest from "../components/ReadMoreTes";
import "../assets/scss/3-elements/_history.scss";

class Departments extends Component {
  render() {
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>Departments</h2>
            <DepartmentTabs />
          </div>
        </div>
      </div>
    );
  }
}
export default Departments;
