import React, { Suspense, lazy } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Topbar from "./cms/pages/global/Topbar";
import Sidebar from "./cms/pages/global/Sidebar";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./cms/theme";
import Dashboard from "./cms/pages/dashboard";
import CmsTeam from "./cms/pages/users";
import Announcements from "./cms/pages/announcements";
import News from "./cms/pages/news";
import Bar from "./cms/pages/bar";
import Form from "./cms/pages/form";
import Line from "./cms/pages/line";
import Pie from "./cms/pages/pie";
import FAQ from "./cms/pages/faq";
import Geography from "./cms/pages/geography";
import Calendar from "./cms/pages/calendar/calendar";
import NoMAtch from "./pages/404";

import 'react-pro-sidebar/dist/css/styles.css';
import './cms/index.css';
import CmsLogin from "./cms/pages/auth/login";
import { Navigate } from "react-router-dom";
import { useStateContext } from "./cms/context/ContextProvider";
import { Outlet } from "react-router-dom";

const ProtectedRoute = ({ element, ...rest }) => {
  const { authReady } = useStateContext()

  return authReady ? (
    <Outlet />
  ) : (
    <Navigate to="/cms/login" replace />
  );
};
const CmsRoutes = () => {
  const [theme, colorMode] = useMode();
  const { authReady } = useStateContext()
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {authReady && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {authReady && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/login" element={<CmsLogin />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/users" element={<CmsTeam />} />
                <Route path="/news" element={<News />} />
                <Route path="/announcements" element={<Announcements />} />
                <Route path="/form" element={<Form />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/geography" element={<Geography />} />
              </Route>
              <Route path="*" element={<NoMAtch />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default CmsRoutes;
