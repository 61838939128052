import React, { useEffect, useState } from 'react';

function Api() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('/api/leaders')
      .then(response => response.json())
      .then(data => {
        console.log('Data fetched from API:', data); // Log the data to the console
        setData(data);
      })
    //   .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
    </div>
  );
}

export default Api;