import React, { Component } from "react";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import InputIcon from "@mui/icons-material/Input";
import { Link, Outlet } from "react-router-dom";
import ReadMoreTest from "../components/ReadMoreTes";
import "../assets/scss/3-elements/_history.scss";

const history = `The Principal, Forestry Training Institute, Olmotonyi (FTI) invites suitably qualified applicants for admission into various programmes.

Applicants are required to apply online through this portal and follow instructions given to complete their applications. Payment can be done via mobile money (M-Pesa, Airtel Money, TTCL Pesa, HaloPesa, EzyPesa and Tigo Pesa/ZANTEL) or directly into FTI bank account - after getting control number.

In case of Technical Problems during the application process please or for issues to deal with courses of study and clarifications of Entry requirements please contact our Admissions office at 0685 904 299.`;
const majorRoles = [
  {
    description:
      "Read carefully to understand the Programmes Admission Requirements...",
    link: "https://www.fti.ac.tz/programs",
  },
  {
    description: "Click here to appply now...",
    link: "https://saris.maliasili.go.tz/Registration/20",
  },
];

class Background extends Component {
  render() {
    const paragraphs = history
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph.trim()}</p>);
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>Online Application</h2>
            {paragraphs}
          </div>

          <div className="section">
            <ul>
              {majorRoles.map((value, index) => (
                <li key={index} className="core-value-item">
                  <ContentPasteSearchIcon
                    style={{
                      marginRight: "8px",
                      verticalAlign: "middle",
                      color: "#0d6526",
                      fontSize: "50px",
                    }}
                  />
                  {value.description}
                  <Link to={value.link}>
                    <InputIcon
                      style={{
                        marginRight: "8px",
                        verticalAlign: "middle",
                        color: "red",
                        fontSize: "20px",
                      }}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default Background;
