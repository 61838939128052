import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client.js";
import { createRef } from "react";
import { useStateContext } from "../../context/ContextProvider.jsx";
import { useState } from "react";

export default function CmsLogin() {
  const emailRef = createRef()
  const passwordRef = createRef()
  const { setUser, setToken } = useStateContext()
  const [message, setMessage] = useState(null)
  const navigate = useNavigate();

  const onSubmit = ev => {
    ev.preventDefault()

    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    }
    axiosClient.post('/login', payload)
      .then(({ data }) => {
        console.log("data", data)
        setUser(data.user)
        setToken(data.token);
        localStorage.setItem('token', data.token)
        navigate('/cms')
      })
      .catch((err) => {
        console.log("err", err)
        setMessage(err?.message)
      })
  }

  return (
    <div className="login-signup-form animated fadeInDown">
      <div className="form">
        <form onSubmit={onSubmit}>
          <h1 className="title">Login </h1>

          {message &&
            <div className="alert">
              <p>{message}</p>
            </div>
          }

          <input ref={emailRef} type="email" placeholder="Email" />
          <input ref={passwordRef} type="password" placeholder="Password" />
          <button className="btn btn-block">Login</button>
          <p className="message">Not registered? <Link to="/signup">Create an account</Link></p>
        </form>
      </div>
    </div>
  )
}
