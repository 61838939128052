import React, { Component } from "react";
import "../assets/scss/3-elements/_management.scss";
class TeamMemberGrid extends Component {
  render() {
    /* team member data */

    let data = [
      {
        profileImage: "dr_joseph_makero.jpg",
        profileTitle: "Dr. Joseph S.Makero",
        profileDesignation: "Principal",
        profileEmail: "principal@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "stephen_raphael_juma.jpg",
        profileTitle: "Mr. Stephen Raphael Juma",
        profileDesignation:
          "Deputy Principal Academic, Research and Consultancy",
        profileEmail: "principal@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "dr_angelingis_makatta.jpg",
        profileTitle: "Dr. Angelingis Makatta",
        profileDesignation:
          "Acting Deputy Principal-Planning Finance and Administration",
        profileEmail: "principal@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "simon_kitereja_nsinda.jpg",
        profileTitle: "Mr. Simon K. Nsinda",
        profileDesignation:
          "Head of Department of Geoinformatics for Natural Resources Management",
        profileEmail: "stephen.juma@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "sitta_m_buluma.jpg",
        profileTitle: "Mr. Sitta B. Malulu",
        profileDesignation: "Head of Department of Forestry Science",
        profileEmail: "ict@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "mzamiru_kyaruzi.jpg",
        profileTitle: "Mr. Muzamiru A. Kyaruzi",
        profileDesignation:
          "Head of Department of Social Forestry and Environment",
        profileEmail: "academic@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "team-4.jpg",
        profileTitle: "Ms. Happiness Y. Ibrahimu",
        profileDesignation: "Head of Procurement Unit",
        profileEmail: "admin@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "team-2.jpg",
        profileTitle: "CPA (T) Flora P. Masai",
        profileDesignation: "Head of Finance and Accounts",
        profileEmail: "bertold.kitta@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "mundhari_muhana.jpg",
        profileTitle: "Dr. Mundhari A. Muhana",
        profileDesignation: "Head of Medical Unit",
        profileEmail: "arafa.nyorita@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
      {
        profileImage: "team-4.jpg",
        profileTitle: "CPA (T) Josephine T. Njau",
        profileDesignation: "Head of Audit Unit",
        profileEmail: "amwene.chanai@fti.ac.tz",
        socialLinks: {
          facebook: "//www.facebook.com",
          twitter: "//www.twitter.com",
          linkedin: "//www.linkedin.com",
          googlePlus: "//plus.google.com",
        },
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-3 col-sm-6 col-12 section-space--bottom--30"
          key={i}
        >
          <div className="team">
            <div className="image">
              <img src={`assets/img/team/${val.profileImage}`} alt="" />
            </div>
            <div className="content">
              <h3 className="title">{val.profileTitle}</h3>
              <span>{val.profileDesignation}</span>
              <button className="view-profile">View Profile</button>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  team member area ====================*/}
        <div className="team-member-area section-space--inner--20">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">Our Team <span className="title-icon" /></h2>
                        </div>
                    </div> */}
            </div>
            {/* <div className="row"> */}
            {/* <div className="col-lg-12"> */}
            <div className="team-member-wrapper">
              {/* <div className="row"> */}
              {Datalist}
              {/* </div> */}
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
        {/*====================  End of team member area  ====================*/}
      </div>
    );
  }
}

export default TeamMemberGrid;
