import React, { Component } from 'react';
import Announcements from './Announcements';

class Sidebar extends Component{
    render(){

        /* sidebar category */

        let categoryData = [
            {categoryLink: "news-left-sidebar", categoryName: "MNRT"},
            {categoryLink: "news-left-sidebar", categoryName: "TFS"},
            {categoryLink: "news-left-sidebar", categoryName: "TaFF"},
            {categoryLink: "news-left-sidebar", categoryName: "NECTA"},
            {categoryLink: "news-left-sidebar", categoryName: "NACTVET"},
            {categoryLink: "news-left-sidebar", categoryName: "eGA"},
            {categoryLink: "news-left-sidebar", categoryName: "HELSB"}
        ];

        let categoryDataList = categoryData.map((val, i)=>{
            return(
                <li key={i}><a href={`${process.env.PUBLIC_URL}/${val.categoryLink}`}>{val.categoryName}</a></li>
            )
        });
        
        /* sidebar popular post */
        
        // let popularPostData = [
        //     {postImage: 'sidebar-blog-1.jpg', postTitle: 'What are Groundworkers and what do they do?', postLink: 'news-details-left-sidebar', postDate: '30 October 2019'},
        //     {postImage: 'sidebar-blog-2.jpg', postTitle: 'What are Groundworkers and what do they do?', postLink: 'news-details-left-sidebar', postDate: '30 October 2019'},
        //     {postImage: 'sidebar-blog-3.jpg', postTitle: 'What are Groundworkers and what do they do?', postLink: 'news-details-left-sidebar', postDate: '30 October 2019'}
        // ];

        // let popularPostDataList = popularPostData.map((val, i)=>{
        //     return(
        //         <div className="sidebar-blog" key={i}>
        //         <a href="news-details-left-sidebar.html" className="image"><img src={`assets/img/blog/${val.postImage}`} alt="" /></a>
        //             <div className="content">
        //                 <h5><a href={`${process.env.PUBLIC_URL}/${val.postLink}`}>What are Groundworkers and what do they do?</a></h5>
        //                 <span>{val.postDate}</span>
        //             </div>
        //         </div>
        //     )
        // });

        
        /* sidebar tag */
        
        
        //     {tagLink: "news-left-sidebar", tagName: "Architecture"},
        //     {tagLink: "news-left-sidebar", tagName: "Concrete"},
        //     {tagLink: "news-left-sidebar", tagName: "Flooring"},
        //     {tagLink: "news-left-sidebar", tagName: "Consulting"},
        //     {tagLink: "news-left-sidebar", tagName: "Interior"},
        //     {tagLink: "news-left-sidebar", tagName: "Planning"}
        // ];

        // let tagDataList = tagData.map((val, i)=>{
        //     return(
        //         <li key={i}><a href={`${process.env.PUBLIC_URL}/${val.tagLink}`}>{val.tagName}</a></li>
        //     )
        // });

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Search</h3>
                        <div className="sidebar-search">
                            <form action="#">
                                <input type="text" placeholder="Search" />
                                <button><i className="ion-ios-search" /></button>
                            </form>
                        </div>
                    </div>

                    <div className="sidebar">
                    <Announcements/>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Important Links</h3>
                        <ul className="sidebar-list">
                            {categoryDataList}
                        </ul>
                    </div>
                    {/* <div className="sidebar">
                        <h3 className="sidebar-title">Popular Tags</h3>
                        <ul className="sidebar-tag">
                            {tagDataList}
                        </ul>
                    </div> */}
                </div>
            </div>
        )
    }
}


export default Sidebar;