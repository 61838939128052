// App.js
import React, { Suspense, lazy, Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CmsRoutes from "./routes.cms";
import WebRoutes from "./routes.web";
import { ContextProvider } from "./cms/context/ContextProvider";
function App() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path="/cms/*" element={<CmsRoutes />} />
            <Route path="/*" element={<WebRoutes />} />
            {/* <Route path="/cms" component={CMS} /> */}
          </Routes>
        </Suspense>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
