import React, { Component, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Announcements from "../blog/components/Announcements";
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 1340) : text}
      <span
        onClick={toggleReadMore}
        className="read-or-hide"
        style={{ color: "green" }}
      >
        {isReadMore ? (
          <>
            ...Read more <ExpandMoreIcon />
          </>
        ) : (
          <>
            ...Show less <ExpandLessIcon />
          </>
        )}
      </span>
    </p>
  );
};
class PrincialStatement extends Component {
  render() {
    return (
      <div>
        {/*====================  Principals' Statement====================*/}
        <div className="container">
          <div className="col-12 university-container">
            {/* <h1 className="about-us-content__small-title">MESSAGE FROM PRINCIPAL</h1> */}
            <div className="image-container">
              <img
                src="assets/img/about/1213.png" // Replace with the actual image URL or path
                alt="University Imagie"
                className="university-imagie"
              />
              <p className="principal-statement">
                <ReadMore className="principal-statement">
                  I’m pleased to introduce you to the Forestry Training
                  Institute, Olmotonyi (FTI). Our institute is located at
                  Olmotonyi, 15 kilometres from Arusha Business Centre, which is
                  the door to the world’s great wildlife heritage, including the
                  Ngorongoro Crater and Serengeti National Parks, and the hub of
                  the East African Community (EAC). The institute aspires to
                  become a society with practical knowledge, skills, and
                  attitudes for sustainable development. With a view to
                  realizing its aspirations, the institute will have five (5)
                  Technical Education Training (TET) programs by 2020: The Basic
                  Technician Certificate in Forestry, the Technician Certificate
                  in Forestry, the Ordinary Diploma in Forestry, the Ordinary
                  Diploma in Urban Forestry and Landscaping, and the Ordinary
                  Diploma in Geoinformatics for Natural Resources Management.
                  The Institute is also registered by the National Council for
                  Technical and Vocational Education Training (NACTVET) to offer
                  these programs. FTI is the only training institute in Tanzania
                  and the East African Region to offer forestry training. In so
                  doing, the institute supports the growth and development of
                  the economy in the East African Region and beyond. As a result
                  of its support, FTI has a regional reputation in different
                  African countries, including Cameroun, Botswana, Ethiopia,
                  Gambia, Kenya, Lesotho, Malawi, Rwanda, Sierra Leone, Somalia,
                  Eswatini, Uganda, and Zambia. On behalf of the FTI Management,
                  I would like to thank you for your interest in joining our
                  institute, which has over 87 years of existence and
                  experience, professional and committed staff, and adequate
                  facilities and equipment for meeting our aim of imparting
                  knowledge, skills, and attitude to our prospective students.
                  We will continue to be committed to offering high-quality
                  training as well as research and consulting in forestry and
                  environmental conservation, focusing on sustainable management
                  and use of forest resources, which will enhance the
                  contribution of the forestry sector towards fulfilling
                  national and global interests.
                </ReadMore>
              </p>
            </div>
          </div>
          <div className="university-announcement">
            <Announcements />
          </div>
        </div>
      </div>
    );
  }
}

export default PrincialStatement;

